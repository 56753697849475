import { MeasurementsQueryResult } from '../../../timeSeries/types'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import React from 'react'
import { AggregationType, aggregateSeries } from '../../../timeSeries/aggregation/aggregateSeries'
import { SeriesGroupByKey, groupSeries } from '../../../timeSeries/aggregation/groupSeries'
import Highcharts from 'highcharts'
import noop from 'lodash/noop'

export interface TimeSeriesPieChartOptions {
    groupBy: SeriesGroupByKey
    aggType: AggregationType
    labelTranslater?: (label: string) => string
}

interface TimeSeriesPieChartProps {
    options?: TimeSeriesPieChartOptions
    series: MeasurementsQueryResult[]
}

const buildChartFormatOptions = (): Highcharts.Options => {
    return {
        chart: {
            type: 'pie',
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                animation: false,
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `${this.key ?? ''} ${this.percentage?.toFixed(1) ?? '-'} %`
                    },
                },
                innerSize: '50%',
            },
        },
        tooltip: {
            pointFormat: `<b>{point.y:.0f} {point.unit}</b>`,
        },
        exporting: {
            enabled: false,
        },
    }
}

const PieChart: React.FC<TimeSeriesPieChartProps> = ({ series, options }) => {
    const labelTranslater = options?.labelTranslater || noop
    const groupedSeries = groupSeries(
        series.map((s) => s.data),
        options?.groupBy || 'subType'
    )
    const aggregatedSeries = Object.entries(groupedSeries).map(([key, values]) => {
        return {
            name: labelTranslater(key),
            y: aggregateSeries(values, options?.aggType || 'sum'),
            unit: values[0].unit,
        }
    })
    const chartFormatOptions = buildChartFormatOptions()

    return (
        <HighchartsChart
            title="Pie Chart"
            type="pie"
            options={{
                ...chartFormatOptions,
                series: [
                    {
                        data: aggregatedSeries,
                    },
                ],
            }}
        />
    )
}

export default PieChart

import { FC } from 'react'
import { Outlet, Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Tab, Tabs } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import SiteDashboardFallback from './fallback'
import SiteHeader from '../siteHeader.component'
import { DashboardKeys, DEFAULT_DASHBOARD_KEY } from './dashboardKeys'
import { TimeSeriesProvider } from './timeSeries/useTimeSeries'
import { useSite } from '../useSite'
import { SiteTypeIds } from '@/types/siteTypes'
import SettingsIcon from '@mui/icons-material/Settings'
import PeriodSelector from './components/PeriodSelector'

const SiteDashboardLayout: FC = () => {
    const { t } = useTranslation('site')
    const { dashboardId = DEFAULT_DASHBOARD_KEY } = useParams()
    const { site } = useSite()
    const showOccupancy = site?.siteTypeId === SiteTypeIds.HOTEL

    return (
        <Box>
            <SiteHeader title={t('navLinks.dashboard')} />
            <Box sx={({ palette }) => ({ my: 2, borderBottom: `1px solid ${palette.grey[300]}`, gap: 1 })}>
                <PeriodSelector my={0.5} />
                <Tabs value={dashboardId} scrollButtons="auto">
                    <Tab
                        label={t('dashboard.keys.main')}
                        value={DashboardKeys.MAIN}
                        component={Link}
                        to={DashboardKeys.MAIN}
                    />
                    <Tab
                        label={t('dashboard.keys.energy')}
                        value={DashboardKeys.ENERGY}
                        component={Link}
                        to={DashboardKeys.ENERGY}
                    />
                    <Tab
                        label={t('dashboard.keys.hvac')}
                        value={DashboardKeys.HVAC}
                        component={Link}
                        to={DashboardKeys.HVAC}
                    />
                    <Tab
                        label={t('dashboard.keys.meteo')}
                        value={DashboardKeys.METEO}
                        component={Link}
                        to={DashboardKeys.METEO}
                    />
                    {showOccupancy && (
                        <Tab
                            label={t('dashboard.keys.occupancy')}
                            value={DashboardKeys.OCCUPANCY}
                            component={Link}
                            to={DashboardKeys.OCCUPANCY}
                        />
                    )}
                    <Tab
                        value={DashboardKeys.FEEDS}
                        icon={<SettingsIcon fontSize="small" />}
                        aria-label={t('dashboard.keys.feeds')}
                        title={t('dashboard.keys.feeds')}
                        sx={{ ml: 'auto' }}
                        component={Link}
                        to={DashboardKeys.FEEDS}
                    />
                </Tabs>
            </Box>
            <ErrorBoundary fallbackRender={SiteDashboardFallback}>
                <TimeSeriesProvider>
                    <Outlet />
                </TimeSeriesProvider>
            </ErrorBoundary>
        </Box>
    )
}

export default SiteDashboardLayout

import { FC } from 'react'
import { Grid, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import LanguageSwitch from './LanguageSwitch'

const baseContent = `&copy; Agrid ${new Date().getFullYear()}`

interface FooterProps {
    links?: { title: string; path: string; target?: string }[]
}

const Footer: FC<FooterProps> = ({ links }) => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ height: '50px', color: 'primary.main', pb: 8 }}
            component={'footer'}
        >
            <Grid item>
                <span dangerouslySetInnerHTML={{ __html: baseContent }} />
            </Grid>
            {links?.map((link) => (
                <Grid item key={`footer-link-${link.path}`}>
                    <Link component={RouterLink} to={link.path} target={link?.target || '_self'}>
                        {link.title}
                    </Link>
                </Grid>
            ))}
            <Grid item>
                <LanguageSwitch />
            </Grid>
        </Grid>
    )
}

export default Footer

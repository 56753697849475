import React, { useEffect, useState } from 'react'
import {
    Alert,
    Backdrop,
    Box,
    Badge,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Tab,
    Tabs,
    TextField,
    Select,
    MenuItem,
    useMediaQuery,
} from '@mui/material'
import SetupComponent from '@/components/Global/setup.component'
import { getObjectsForSite } from '@/services/realtime.service'
import useLocale from '@/utils/locale/locale.hook'
import { useSite } from '../useSite'
import hashFunction from 'object-hash'
import { Button } from '@mui/material'
import moment from 'moment'
import 'moment/locale/fr'
import { useParams } from 'react-router'
import { getConstraints, getGroups, postCheckin, postConstraints, postGroups } from '@/services/constraints.service'
import { event } from '@/utils/ga.utils'
import { setupBuildings } from '@/services/site.service'
import SaveMenu from './components/save-menu.component'
import { Tab1Component } from './components/tab-1.component'
import { Tab2Component } from './components/tab-2.component'
import { Tab3Component } from './components/tab-3.component'
import { isDemo } from '@/services/utils.service'
import useAuth from '@/context/useAuth'
import { useTranslation } from 'react-i18next'

const DialogComponent = ({ dialog, setDialog, title, description, noText, yesText, accept, reset }) => {
    return (
        <Dialog
            open={dialog}
            onClose={() => {
                reset()
                setDialog(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {noText && <Button onClick={() => setDialog(false)}>{noText}</Button>}
                <Button onClick={accept} autoFocus>
                    {yesText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const ConstraintsPage = () => {
    const { site, object, objectsQuery } = useSite()
    const refetchObjects = objectsQuery.refetch
    const { hash, name, siteType } = site
    const params = useParams()
    const { t } = useTranslation('site', { keyPrefix: 'constraints' })
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const modal = params.objectId !== undefined
    const { me } = useAuth()
    const locale = useLocale()
    const [constraints, setConstraints] = useState(null)
    const [loading, setLoading] = useState(true)
    const [rawObjects, setRawObjects] = useState([])
    const [dialog, setDialog] = useState(false)
    const [groupDialog, setGroupDialog] = useState(false)
    const [objectUpdates, setObjectUpdates] = useState([])
    const [groups, setGroups] = useState([])
    const [checkIn, setCheckIn] = useState('14:00:00')
    const [checkOut, setCheckOut] = useState('11:00:00')
    const [weekendCheckIn, setWeekendCheckIn] = useState(null)
    const [weekendCheckOut, setWeekendCheckOut] = useState(null)
    const [defaultCheckin, setDefaultCheckin] = useState(null)
    const [defaultCheckout, setDefaultCheckout] = useState(null)
    const [constraintsUpdated, setConstraintsUpdated] = useState(false)
    const [rawConstraints, setRawConstraints] = useState(null)
    const [initConstraints, setInitConstraints] = useState(null)
    const [buildings, setBuildings] = useState([])
    const [holidays, setHolidays] = React.useState([])
    const [objectNewName, setObjectNewName] = useState(object?.name)
    const [currentGroup, setCurrentGroup] = useState({
        name: null,
        objects: [],
    })
    const [controllers, setControllers] = useState([])
    const [observationEndDate, setObservationEndDate] = useState(null)
    const [modified, setModified] = useState(false)
    const [selectedTab, setSelectedTab] = useState(1)
    const [open, setOpen] = useState(false)
    const [ephemerous, setEphemerous] = useState(false)

    useEffect(() => {
        if (site) {
            setCurrentGroup({ name: null, objects: [] })
            setLoading(true)
            fetchObjects()
            fetchConstraints()
            fetchGroups()
        }
    }, [site, constraintsUpdated])

    useEffect(() => {
        setControllers([])
    }, [site])

    const fetchGroups = async () => {
        setGroups([])
        const res = await getGroups(hash)
        if (res?.groups) {
            setGroups(res?.groups)
        }
    }

    const fetchObjects = async () => {
        setRawObjects([])

        const res = await getObjectsForSite({ hash })
        if (res && res.objects) {
            let objects = res.objects?.filter((el) => !el?.Hidden)
            setRawObjects(objects)
            if (res?.site) {
                let newCheckIn =
                    res?.site?.CheckIn?.split(':')[0]?.length > 1 ? res?.site?.CheckIn : `0${res?.site?.CheckIn}`
                let newCheckOut =
                    res?.site?.CheckOut?.split(':')[0]?.length > 1 ? res?.site?.CheckOut : `0${res?.site?.CheckOut}`
                setCheckIn(newCheckIn)
                setCheckOut(newCheckOut)
                setDefaultCheckin(newCheckIn)
                setDefaultCheckout(newCheckOut)
                setObservationEndDate(res?.site?.ObservationEndDate)
                if (res?.site?.WeekendCheckIn) {
                    setWeekendCheckIn(res?.site?.WeekendCheckIn)
                }
                if (res?.site?.WeekendCheckOut) {
                    setWeekendCheckOut(res?.site?.WeekendCheckOut)
                }
            }
        }
        setLoading(false)
    }

    const fetchConstraints = async () => {
        const res = await getConstraints(hash)
        if (res && res.constraints) {
            setConstraints({ ...res?.constraints })
            setRawConstraints({ ...res?.constraints })
            let newConstraints = { ...res?.constraints }

            for (let i = 0; i < Object.keys(res?.constraints).length; i++) {
                let key = Object.keys(res?.constraints)[i]

                newConstraints[key] = {
                    ...res?.constraints[key],
                    fingerPrint: createFingerPrint(res?.constraints[key]),
                }
            }

            setInitConstraints(newConstraints)
        }
        if (res?.holidays) {
            let newHolidays = res?.holidays?.map((el) => {
                return { ...el, date: el.StartDate }
            })
            setHolidays(newHolidays)
        }
    }

    const createFingerPrint = (object) => {
        return hashFunction({
            events: object?.events || [],
            pilotEvents: object?.pilotEvents || [],
            constraintMin: object?.constraintMin,
            constraintMax: object?.constraintMax,
            constraintMaxHeat: object?.constraintMaxHeat,
            constraintMinHeat: object?.constraintMinHeat,
            inherited: object?.inherited,
            pilot: object?.pilot,
        })
    }

    const saveObject = async ({
        events,
        constraintMin,
        constraintMax,
        applyToAll,
        constraintMinHeat,
        constraintMaxHeat,
        pilot,
        noAgenda,
        pilotEvents,
    }) => {
        let newConstraints = { ...constraints }
        events = events.map((el) => {
            return {
                ...el,
                startDate: moment(el.startDate).format('YYYY-MM-DD'),
                startTime: el.startTime,
                endDate: moment(el.endDate).format('YYYY-MM-DD'),
                endTime: el.endTime,
            }
        })
        pilotEvents = pilotEvents.map((el) => {
            return {
                ...el,
                startDate: moment(el.startDate).format('YYYY-MM-DD'),
                startTime: el.startTime,
                endDate: moment(el.endDate).format('YYYY-MM-DD'),
                endTime: el.endTime,
            }
        })
        newConstraints[object.objectId] = {
            events,
            constraintMin: parseFloat(constraintMin),
            constraintMax: parseFloat(constraintMax),
            constraintMinHeat: parseFloat(constraintMinHeat),
            constraintMaxHeat: parseFloat(constraintMaxHeat),
            inherited: 1,
            pilot: pilot ? 1 : 0,
            noAgenda: noAgenda ? 1 : 0,
            pilotEvents: pilotEvents,
            previousConstraintMin: rawConstraints[object.objectId]?.constraintMin,
            previousConstraintMax: rawConstraints[object.objectId]?.constraintMax,

            previousConstraintMinHeat: rawConstraints[object.objectId]?.constraintMinHeat,
            previousConstraintMaxHeat: rawConstraints[object.objectId]?.constraintMaxHeat,
        }
        console.log('newConstraints[object.objectId]', rawConstraints[object.objectId], object)
        if (applyToAll) {
            let objectIds = []
            if (object?.objectTypeId === 1) {
                objectIds = rawObjects.map((el) => el.ObjectId)
            } else {
                let spaceType = object?.spaceType
                objectIds = rawObjects.filter((el) => el.SpaceType == spaceType).map((el) => el.ObjectId)
            }

            for (let i = 0; i < objectIds?.length; i++) {
                newConstraints[objectIds[i]] = {
                    events,
                    pilotEvents,
                    pilot: pilot ? 1 : 0,
                    noAgenda: noAgenda ? 1 : 0,
                    constraintMin: parseFloat(constraintMin),
                    constraintMax: parseFloat(constraintMax),
                    constraintMinHeat: parseFloat(constraintMinHeat),
                    constraintMaxHeat: parseFloat(constraintMaxHeat),
                    inherited: 1,
                    previousEvents:
                        newConstraints[objectIds[i]]?.previousEvents || newConstraints[objectIds[i]]?.events,
                    previousConstraintMin:
                        newConstraints[objectIds[i]]?.previousConstraintMin ||
                        newConstraints[objectIds[i]]?.constraintMin,
                    previousConstraintMax:
                        newConstraints[objectIds[i]]?.previousConstraintMax ||
                        newConstraints[objectIds[i]]?.constraintMax,

                    previousConstraintMinHeat:
                        newConstraints[objectIds[i]]?.previousConstraintMinHeat ||
                        newConstraints[objectIds[i]]?.constraintMinHeat,
                    previousConstraintMaxHeat:
                        newConstraints[objectIds[i]]?.previousConstraintMaxHeat ||
                        newConstraints[objectIds[i]]?.constraintMaxHeat,
                }
            }
        }
        setConstraints(newConstraints)
        setConstraintsUpdated(true)
        // setModified(true)
    }

    const applyConstraintsToOther = (type) => {
        let newConstraints = { ...constraints }
        let object_constraints = newConstraints[object?.objectId]
        let objectIds = []
        if (type === 'space_type') {
            if (object?.objectTypeId === 1) {
                objectIds = rawObjects.map((el) => el.ObjectId)
            } else {
                let spaceType = object?.spaceType
                objectIds = rawObjects.filter((el) => el.SpaceType == spaceType).map((el) => el.ObjectId)
            }
        } else if (type) {
            objectIds = groups.find((el) => el.hash == type)?.objects
        }

        for (let i = 0; i < objectIds?.length; i++) {
            newConstraints[objectIds[i]] = {
                events: object_constraints.events,
                constraintMin: parseFloat(object_constraints?.constraintMin),
                constraintMax: parseFloat(object_constraints?.constraintMax),
                constraintMinHeat: parseFloat(object_constraints?.constraintMinHeat),
                constraintMaxHeat: parseFloat(object_constraints?.constraintMaxHeat),
                inherited: 1,
                pilot: object_constraints.pilot,
                noAgenda: object_constraints.noAgenda,
                previousEvents: newConstraints[objectIds[i]]?.previousEvents || newConstraints[objectIds[i]]?.events,
                previousConstraintMin:
                    newConstraints[objectIds[i]]?.previousConstraintMin || newConstraints[objectIds[i]]?.constraintMin,
                previousConstraintMax:
                    newConstraints[objectIds[i]]?.previousConstraintMax || newConstraints[objectIds[i]]?.constraintMax,
                previousConstraintMinHeat:
                    newConstraints[objectIds[i]]?.previousConstraintMinHeat ||
                    newConstraints[objectIds[i]]?.constraintMinHeat,
                previousConstraintMaxHeat:
                    newConstraints[objectIds[i]]?.previousConstraintMaxHeat ||
                    newConstraints[objectIds[i]]?.constraintMaxHeat,
            }
        }

        setConstraints(newConstraints)
        return newConstraints
    }

    const save = async (constraints) => {
        setLoading(true)
        // return;
        if (buildings?.length > 0) {
            await setupBuildings(hash, { buildings })
        }

        let modifiedConstraints = {}
        for (let i = 0; i < Object.keys(constraints).length; i++) {
            let key = Object.keys(constraints)[i]
            let fingerPrint = createFingerPrint(constraints[key])

            console.log('fingerPrint', key, fingerPrint)
            if (fingerPrint !== initConstraints[key]?.fingerPrint) {
                modifiedConstraints[key] = {
                    ...constraints[key],
                    objectName: rawObjects.find((el) => el.ObjectId == key)?.Name,
                }
            }
        }
        console.log('modifiedConstraints', modifiedConstraints)
        if (constraintsUpdated) {
            let splittedConstraints = []
            for (let i = 0; i < Object.keys(modifiedConstraints).length; i++) {
                let key = Object.keys(modifiedConstraints)[i]
                //splittedConstraints[0][key] = modifiedConstraints[key];
                let index = Math.floor(i / 100)
                if (splittedConstraints.length <= index) {
                    splittedConstraints[index] = {}
                    splittedConstraints[index][key] = modifiedConstraints[key]
                } else splittedConstraints[index][key] = modifiedConstraints[key]
            }
            if (splittedConstraints.length > 0) {
                for (let i = 0; i < splittedConstraints.length; i++) {
                    if (i > 0) {
                        await postConstraints(
                            {
                                constraints: splittedConstraints[i],
                                holidays,
                            },
                            hash
                        )
                    } else {
                        await postConstraints(
                            {
                                constraints: splittedConstraints[i],
                                objectUpdates,
                                holidays,
                            },
                            hash
                        )
                    }
                }
            } else if (objectUpdates?.length > 0) {
                await postConstraints(
                    {
                        objectUpdates,
                        holidays,
                    },
                    hash
                )
            }
        }

        if (objectNewName && objectNewName !== object?.name) {
            let newObjects = rawObjects?.map((el) => {
                if (el?.ObjectId === object.objectId) return { ...el, Name: objectNewName }
                else return el
            })
            setRawObjects(newObjects)
        }

        if ((objectNewName && objectNewName !== object?.name) || modified) {
            await postConstraints(
                {
                    newObject: {
                        ObjectId: object?.objectId,
                        NewName: objectNewName,
                    },
                    holidays,
                },
                hash
            )
        }

        if ((checkIn || checkOut) && siteType === 'Hotel') {
            await applyCheckin()
        }
        // await sleep(1000);
        // await fetchObjects()
        await refetchObjects()
        setOpen(true)
        setLoading(false)
        event('Constraints', 'Change constraints')
        setModified(false)
    }

    const applyReco = async () => {
        let newConstraints = constraints
        for (let i = 0; i < rawObjects?.length; i++) {
            let id = rawObjects[i].ObjectId
            newConstraints[id] = {
                ...constraints[id],
                constraintMax: 26,
                constraintMin: 19,
            }
        }
        setConstraints(newConstraints)
        setConstraintsUpdated(true)
        // await sleep(1000);
        await save(constraints)
        // setShowSnackBar(true);
    }

    const changeSpaceType = async (objectId, objectType) => {
        await postConstraints(
            {
                objectUpdates: [{ objectId: objectId, objectType: objectType }],
                holidays,
            },
            hash
        )
    }

    const applyCheckin = async () => {
        if (checkIn != defaultCheckin || checkOut != defaultCheckout) {
            await postCheckin({ checkIn, checkOut }, hash)
        }
    }

    const autoSave = async ({ _holidays, _checkin, _checkout, _buildings, _weekendCheckIn, _weekendCheckOut }) => {
        /** we want to check if something has changed regarding holidays or space types */
        if (_holidays) {
            setHolidays(_holidays)
            await postConstraints(
                {
                    holidays: _holidays?.map((el) => {
                        return { ...el, type: el.type || el?.Type }
                    }),
                },
                hash
            )
        }
        if (_checkin) {
            setCheckIn(_checkin)
            await postCheckin({ checkIn: _checkin, checkOut, weekendCheckIn, weekendCheckOut }, hash)
        }
        if (_checkout) {
            setCheckOut(_checkout)
            await postCheckin({ checkIn, checkOut: _checkout, weekendCheckIn, weekendCheckOut }, hash)
        }
        if (_buildings) {
            setBuildings(_buildings)
            await setupBuildings(hash, { buildings: _buildings })
        }
        if (_weekendCheckIn == null && _weekendCheckOut == null && !(_checkin || _checkout)) {
            setWeekendCheckIn(null)
            setWeekendCheckOut(null)
            await postCheckin({ weekendCheckIn: null, weekendCheckOut: null, checkIn, checkOut }, hash)
        } else {
            if (_weekendCheckIn) {
                setWeekendCheckIn(_weekendCheckIn)
                await postCheckin({ weekendCheckIn: _weekendCheckIn, weekendCheckOut, checkIn, checkOut }, hash)
            }
            if (_weekendCheckOut) {
                setWeekendCheckOut(_weekendCheckOut)
                await postCheckin({ weekendCheckIn, weekendCheckOut: _weekendCheckOut, checkIn, checkOut }, hash)
            }
        }
    }

    const updateCurrentGroup = (objectId) => {
        let index = currentGroup?.objects?.indexOf(objectId)
        if (index === -1) {
            setCurrentGroup({
                ...currentGroup,
                objects: [...currentGroup.objects, objectId],
            })
        } else {
            setCurrentGroup({
                ...currentGroup,
                objects: currentGroup?.objects?.filter((item) => item !== objectId),
            })
        }
    }

    const updateGroup = async () => {
        setGroupDialog(false)
        if (!currentGroup.name) return
        setLoading(true)

        let find = groups.find((el) => el?.name === currentGroup.name)
        let newGroups = groups
        if (!find) {
            newGroups = groups
            newGroups.push(currentGroup)
        } else {
            newGroups = newGroups.map((el) => {
                if (el?.name === currentGroup.name) return currentGroup
                else return el
            })
        }

        await postGroups({ groups: newGroups }, hash)
        setGroups(newGroups)

        // setCurrentGroup({
        //   objects: [],
        //   name: null,
        // });
        setLoading(false)
    }

    const removeGroup = async () => {
        setLoading(true)
        let newGroups = groups.filter((el) => el.name !== currentGroup?.name)
        setGroups(newGroups)

        await postGroups({ groups: newGroups }, hash)
        setCurrentGroup({
            objects: [],
            name: null,
        })
        setLoading(false)
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setOpen(false)}
            >
                <Alert severity="success" sx={{ width: '100%' }} on>
                    {t('siteConfiguration.feedback.success')}
                </Alert>
            </Snackbar>
            {ephemerous && (
                <EphermerousDialog
                    ephemerous={ephemerous}
                    setEphemerous={setEphemerous}
                    submit={async (days) => {
                        setEphemerous(false)
                        let object_id = object?.objectId
                        let newConstraints = constraints
                        newConstraints[object_id] = {
                            ...newConstraints[object_id],
                            ephemerous: true,
                            old_constraint: initConstraints[object_id],
                            days: days,
                        }

                        if (object?.pilot !== newConstraints[object_id]?.pilot) {
                            newConstraints[object_id]['old_constraint'] = {
                                ...newConstraints[object_id]['old_constraint'],
                                old_pilot: object?.pilot,
                            }
                        }

                        await save(newConstraints)
                    }}
                />
            )}

            {/* <Box style={{ position: "fixed", width: "calc(100% - 240px)", boxSizing: "border-box", left: 240}}> */}
            <Box>
                <DialogComponent
                    dialog={dialog}
                    setDialog={setDialog}
                    title={'Confirmation'}
                    description={locale?.['navBar']?.['confirmation_1']}
                    noText={locale?.['no']}
                    yesText={locale?.['yes']}
                    accept={async () => {
                        event('ApplyRecoConstraints', `${name}`)
                        await applyReco()
                        setDialog(false)
                    }}
                />

                <DialogComponent
                    dialog={groupDialog}
                    setDialog={setGroupDialog}
                    title={locale?.['constraints']?.['newGroup']}
                    reset={() => setCurrentGroup({ name: null, objects: [] })}
                    description={
                        <>
                            <DialogContentText id="group-dialog-description">
                                {locale?.['constraints']?.['groupNameQuestion']}
                            </DialogContentText>
                            <TextField
                                style={{ marginTop: 10 }}
                                value={currentGroup.name}
                                placeholder={locale?.['constraints']?.['groupNamePlaceholder']}
                                onChange={(e) => setCurrentGroup({ ...currentGroup, name: e.target.value })}
                            />
                        </>
                    }
                    yesText={locale?.['constraints']?.['confirmation']}
                    accept={updateGroup}
                />
                {!modal && (
                    <Tabs
                        value={selectedTab}
                        onChange={(event, value) => {
                            setSelectedTab(value)
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {me?.userPermissions?.find(
                            (permission) =>
                                permission?.hash === hash &&
                                permission?.section === 'ConstraintsView' &&
                                ['Manage']?.includes(permission?.grantType)
                        ) || isDemo() ? (
                            <Tab label={t(isMobile ? 'tabs.siteShort' : 'tabs.site')} value={0} />
                        ) : null}

                        <Tab label={t(isMobile ? 'tabs.objectsShort' : 'tabs.objects')} value={1} />
                        {controllers?.length === 1 ||
                        !me?.userPermissions?.find(
                            (permission) =>
                                permission?.hash === hash &&
                                permission?.section === 'ConstraintsView' &&
                                ['Manage']?.includes(permission?.grantType)
                        ) ? null : (
                            <Tab label={t(isMobile ? 'tabs.buildingsShort' : 'tabs.buildings')} value={2} />
                        )}
                    </Tabs>
                )}

                {selectedTab === 0 && (
                    <Tab1Component
                        holidays={holidays}
                        autoSave={autoSave}
                        checkOut={checkOut}
                        checkIn={checkIn}
                        observationEndDate={observationEndDate}
                        weekendCheckIn={weekendCheckIn}
                        weekendCheckOut={weekendCheckOut}
                    />
                )}
                {selectedTab === 1 && (
                    <Tab2Component
                        modal={modal}
                        saveObject={saveObject}
                        constraints={constraints}
                        setModified={setModified}
                        rawObjects={rawObjects}
                        setObjectUpdates={setObjectUpdates}
                        changeSpaceType={changeSpaceType}
                        setRawObjects={setRawObjects}
                        currentGroup={currentGroup}
                        updateCurrentGroup={updateCurrentGroup}
                        objectUpdates={objectUpdates}
                        groups={groups}
                        removeGroup={removeGroup}
                        setGroupDialog={setGroupDialog}
                        updateGroup={updateGroup}
                        groupDialog={groupDialog}
                        setCurrentGroup={setCurrentGroup}
                        objectNewName={objectNewName}
                        setObjectNewName={setObjectNewName}
                        SaveMenu={
                            <Badge color="secondary" variant="dot" invisible={!modified}>
                                <SaveMenu
                                    title={t('siteConfiguration.actions.save')}
                                    saveModifications={async (event) => {
                                        if (event === 'zone') {
                                            await save(constraints)
                                        } else if (event === 'ephemerous') {
                                            setEphemerous(true)
                                        } else {
                                            let out = await applyConstraintsToOther(event)
                                            await save(out)
                                        }
                                    }}
                                    items={groups.filter((el) => el?.objects?.indexOf(object?.objectId) > -1)}
                                />
                            </Badge>
                        }
                    />
                )}
                {selectedTab === 2 && (
                    <Tab3Component
                        updateBuildings={(e) => autoSave({ _buildings: e })}
                        display={selectedTab == 2}
                        _setControllers={setControllers}
                    />
                )}

                {!loading && !(rawObjects?.length > 0) && <SetupComponent />}
                {loading && (
                    <Backdrop open={true} style={{ color: '#fff', zIndex: 20000000 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Box>
        </>
    )
}

export const EphermerousDialog = ({ ephemerous, setEphemerous, submit }) => {
    const [days, setDays] = useState(1)
    const { t } = useTranslation('site', { keyPrefix: 'constraints.ephemerous' })

    const handleClose = () => {
        setEphemerous(false)
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('description')}</DialogContentText>
                    <div
                        style={{
                            width: '100%',
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: 20,
                        }}
                    >
                        <Select
                            autoFocus
                            onChange={(e) => setDays(e.target.value)}
                            width={'100%'}
                            style={{ width: '70%' }}
                            value={days}
                            placeholder="Nombre de jours"
                        >
                            {[...Array(15).keys()]
                                .map((e) => e + 1)
                                .map((e) => (
                                    <MenuItem value={e} key={e}>
                                        {e}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('actions.cancel')}</Button>
                    <Button onClick={() => submit(days)}>{t('actions.save')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConstraintsPage

import { useContext, createContext } from 'react'
import { Meter, ExtendedSeries } from '@/types/meters'
import { useQuery } from '@tanstack/react-query'
import { getMeters } from '@/services/meters.service'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { filterSeries, SeriesFilter } from './timeSeriesFilters'

interface TimeSeriesContextProps {}

interface TimeSeriesContextValue {
    series: ExtendedSeries[]
    isLoading: boolean
    getSeriesUnit: (filters: SeriesFilter) => { unit?: string | null; isUnique: boolean }
    period: {
        from: string
        to: string
        durationDays: number
    }
}

const timeSeriesContextDefaultValues: TimeSeriesContextValue = {
    series: [],
    isLoading: false,
    getSeriesUnit: () => ({ unit: '', isUnique: false }),
    period: {
        from: '',
        to: '',
        durationDays: 0,
    },
}

export const TimeSeriesContext = createContext<TimeSeriesContextValue>(timeSeriesContextDefaultValues)

export const useTimeSeries = () => {
    const context = useContext(TimeSeriesContext)
    if (!context) {
        throw new Error('useTimeSeries must be used within a TimeSeriesProvider')
    }
    return context
}

export const TimeSeriesProvider: React.FC<TimeSeriesContextProps & { children: React.ReactNode }> = ({ children }) => {
    const { siteHash } = useParams()
    if (!siteHash) {
        throw new Error('useTimeSeries must be used within a site route, missing siteHash parameter')
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const calendarStore = useSelector((state) => state.calendarStore)
    const from = moment(calendarStore.start).format('YYYY-MM-DD')
    const to = moment(calendarStore.end).format('YYYY-MM-DD')
    const durationDays = moment(calendarStore.end).diff(moment(calendarStore.start), 'days')

    const metersQuery = useQuery<Meter[]>({
        queryKey: ['meters-for-site', siteHash],
        queryFn: async () => getMeters(siteHash),
        staleTime: Infinity,
    })

    const extendedSeries =
        metersQuery.data
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ?.map(({ series, id, name: meterName, ...meter }) => series.map((s) => ({ ...meter, ...s, meterName })))
            .flat() || ([] as ExtendedSeries[])

    const getSeriesUnit = (filters: SeriesFilter) => {
        const series = filterSeries(extendedSeries, filters)
        if (!series.length) {
            return { unit: '', isUnique: false }
        }
        const unit = series[0].unit
        const isUnique = new Set(series.map((s) => s.unit)).size === 1

        return { unit, isUnique }
    }

    return (
        <TimeSeriesContext.Provider
            value={{
                isLoading: metersQuery.isLoading,
                series: extendedSeries,
                getSeriesUnit,
                period: {
                    from,
                    to,
                    durationDays,
                },
            }}
        >
            {children}
        </TimeSeriesContext.Provider>
    )
}

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import commonFr from './fr/common.json'
import homeFr from './fr/home.json'
import connectFr from './fr/connect.json'
import siteFr from './fr/site.json'
import fallbackFr from './fr/fallback.json'
import userSettingsFr from './fr/userSettings.json'
import contactFr from './fr/contact.json'
import commonEn from './en/common.json'
import homeEn from './en/home.json'
import connectEn from './en/connect.json'
import siteEn from './en/site.json'
import fallbackEn from './en/fallback.json'
import userSettingsEn from './en/userSettings.json'
import contactEn from './en/contact.json'
import hvacFr from './fr/hvac.json'
import hvacEn from './en/hvac.json'

// from https://github.com/i18next/react-i18next/tree/master/example/react-typescript/simple-multi-namespaces

export const defaultNS = 'common'

export const supportedLanguages = ['en', 'fr'] as const

export type SupportedLanguage = (typeof supportedLanguages)[number]

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        debug: true,
        resources: {
            fr: {
                common: commonFr,
                connect: connectFr,
                home: homeFr,
                site: siteFr,
                fallback: fallbackFr,
                userSettings: userSettingsFr,
                contact: contactFr,
                hvac: hvacFr,
            },
            en: {
                common: commonEn,
                connect: connectEn,
                home: homeEn,
                site: siteEn,
                fallback: fallbackEn,
                userSettings: userSettingsEn,
                contact: contactEn,
                hvac: hvacEn,
            },
        },
        defaultNS,
    })

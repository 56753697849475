import { ExtendedSeriesWithMeasurements } from '@/types/meters'
import groupBy from 'lodash/groupBy'

export type SeriesGroupByKey = 'type' | 'subType' | 'meterName'

export const groupSeries = (
    series: ExtendedSeriesWithMeasurements[],
    key: SeriesGroupByKey
): Record<string, ExtendedSeriesWithMeasurements[]> => {
    const grouped = groupBy(
        series.map((s) => ({
            ...s,
            [key]: s[key] || 'unknown',
        })),
        key
    )

    return grouped
}

import EmptyState from './EmptyState'
import { useTranslation } from 'react-i18next'
import { useTimeSeries } from '../timeSeries/useTimeSeries'
import DataIcon from '@/assets/img/illustrations/undraw_data_report.svg'
import { MeterType } from '@/types/meters'
import DashboardSection from './DashboardSection'
import TimeSeriesChart from '../components/timeSeries/TimeSeriesChart'
import { Alert, Typography, Link as MuiLink, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { RoutePaths } from '@/navigation/route'

const OccupancyDashboard = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.occupancy' })
    const { series, isLoading } = useTimeSeries()
    const hasOccupancySeries = series.some((s) => s.type === MeterType.OCCUPANCY)
    if (!isLoading && !hasOccupancySeries) {
        return (
            <EmptyState
                title={t('emptyState.title')}
                description={t('emptyState.description')}
                imgSrc={DataIcon}
                actions={[
                    {
                        title: t('emptyState.contactSupport'),
                        variant: 'outlined',
                        component: Link,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        to: RoutePaths.Contact,
                    },
                ]}
            />
        )
    }
    return (
        <DashboardSection title={t('title')}>
            <Grid item xs={12}>
                <Alert severity="info" sx={{ mb: 2 }}>
                    <Typography mt={-0.25} fontWeight="bold" gutterBottom>
                        {t('betaDisclaimer.title')}
                    </Typography>
                    <Typography variant="body2">
                        {t('betaDisclaimer.description')}{' '}
                        <MuiLink component={Link} to="/contact">
                            {t('betaDisclaimer.contactUs')}
                        </MuiLink>
                    </Typography>
                </Alert>
            </Grid>
            <TimeSeriesChart
                filters={{
                    type: MeterType.OCCUPANCY,
                    series: ['guest_count'],
                }}
                title={t('guestCount')}
                type="bar"
                options={{ disableLegend: true, labelGetter: () => t('guestCount') }}
                size="1"
            />
            <TimeSeriesChart
                filters={{
                    type: MeterType.OCCUPANCY,
                    series: ['room_count'],
                }}
                title={t('roomCount')}
                type="bar"
                options={{ disableLegend: true, labelGetter: () => t('roomCount') }}
                size="1"
            />
        </DashboardSection>
    )
}

export default OccupancyDashboard

import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { uploadFile } from '@/services/s3.service'
import { updateDocuments } from '@/services/site.service'
import { event } from '@/utils/ga.utils'
import { useTranslation } from 'react-i18next'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

export default function DocumentModal({ open, handleClose, selectedDocument, documents, site, documentTypes }) {
    const [name, setName] = React.useState(selectedDocument?.Name)
    const [file, setFile] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [documentType, setDocumentType] = React.useState(selectedDocument?.DocumentType)

    const { t } = useTranslation('site', { keyPrefix: 'documents.myDocuments' })

    const save = async () => {
        setLoading(true)
        let path = selectedDocument?.Path
        let find = documents?.find((el) => el?.DocumentId === selectedDocument?.DocumentId)
        if (file) {
            path = await uploadFile(file, 'buildings')
            if (!path) {
                alert("Erreur lors de l'upload du fichier")
                setLoading(false)
                return
            }
        }
        if (find) {
            documents = documents?.map((el) => {
                if (el?.DocumentId === selectedDocument?.DocumentId) {
                    return { ...el, Path: path, Name: name, DocumentType: documentType }
                } else return el
            })
        } else {
            documents.push({ Path: path, Name: name, DocumentType: documentType })
        }
        await updateDocuments(site.hash, { documents })
        event('SaveDocuments', site.name)
        setLoading(false)
        handleClose()
    }

    const deleteDoc = async () => {
        setLoading(true)
        let newDocs = documents?.filter((el) => el?.DocumentId != selectedDocument?.DocumentId)

        await updateDocuments(site.hash, { documents: newDocs })
        setLoading(false)
        handleClose()
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {selectedDocument?.DocumentId ? t('dialog.edit') : t('dialog.create')}
                    </Typography>
                    <br />
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label={t('dialog.fields.name')}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    {selectedDocument?.DocumentType == 'report' ? null : (
                        <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel id="demo-simple-select-label">{t('dialog.fields.type')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={documentType}
                                label={t('dialog.fields.type')}
                                onChange={(e) => {
                                    setDocumentType(e.target.value)
                                }}
                            >
                                {documentTypes?.map((el) => {
                                    return (
                                        <MenuItem key={el} value={el}>
                                            {t(`documentTypes.${el}`)}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    )}
                    {selectedDocument?.DocumentType !== 'report' && (
                        <input type="file" onChange={(e) => setFile(e?.target?.files[0])} style={{ marginTop: 10 }} />
                    )}
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20,
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20,
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={save}
                                disabled={!name || (!file && !selectedDocument?.Path) || loading}
                            >
                                {t('dialog.actions.save')}
                            </Button>
                            {selectedDocument?.DocumentId && selectedDocument?.DocumentType !== 'report' && (
                                <Button
                                    variant="contained"
                                    style={{ background: 'red' }}
                                    disabled={!selectedDocument?.DocumentId || loading}
                                    onClick={deleteDoc}
                                >
                                    {t('dialog.actions.delete')}
                                </Button>
                            )}
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    )
}

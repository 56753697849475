import { MeasurementsQueryResult } from '../../../timeSeries/types'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import { useParseLineChartOptions, TimeSeriesLineChartOptions } from './utils/parseLineChartOptions'

export type { TimeSeriesLineChartOptions }

type TimeSeriesLineChartProps = TimeSeriesLineChartOptions & {
    series: MeasurementsQueryResult[]
    options?: TimeSeriesLineChartOptions
}

const LineChart: React.FC<TimeSeriesLineChartProps> = ({ series, options }) => {
    const parseLineChartOptions = useParseLineChartOptions(options)

    return <HighchartsChart type="line" options={parseLineChartOptions(series)} />
}

export default LineChart

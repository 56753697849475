import { MeterType, EnergyMetersSubTypes } from '@/types/meters'
import { ExtendedSeries } from '@/types/meters'

const seriesNames = [
    // Energy
    'energy',
    'power',
    'volume',
    'conversion_factor',
    'index',
    'subscribed_power_exceedance_duration',
    // Weather
    'temperature_2m',
    'relative_humidity_2m',
    'apparent_temperature',
    'precipitation',
    'rain',
    'snowfall',
    'cloud_cover',
    'wind_speed_10m',
    'wind_direction_10m',
    'sunshine_duration',
    // HVAC
    'temperature_measured',
    'temperature_setpoint',
    'state',
    'actions_count_temperature_setpoint',
    'actions_count_state_on',
    'actions_count_state_off',
    // occupancy
    'room_count',
    'guest_count',
] as const

export type SeriesName = (typeof seriesNames)[number]

export interface SeriesFilter {
    type?: MeterType
    subType?: EnergyMetersSubTypes
    series: SeriesName[]
}

type Filterable = Pick<ExtendedSeries, 'type' | 'subType' | 'name'>

export function filterSeries<T extends Filterable>(series: T[], filters: SeriesFilter): T[] {
    return series.filter((s) => {
        if (filters.type && s.type !== filters.type) {
            return false
        }

        if (filters.subType && s.subType !== filters.subType) {
            return false
        }

        return filters.series.includes(s.name as SeriesName)
    })
}

import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'

export default function SaveMenu({ title, saveModifications, items }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const { t } = useTranslation('site', { keyPrefix: 'constraints.saveMenu' })

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (hash) => {
        setAnchorEl(null)
        saveModifications(hash)
    }

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disableElevation
                variant="outlined"
                endIcon={<KeyboardArrowDownIcon />}
            >
                {title}
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => handleClose('zone')}>
                    <div style={{ fontSize: 14 }}>{t('saveForObject')}</div>
                </MenuItem>

                <MenuItem onClick={() => handleClose('ephemerous')}>
                    <div style={{ fontSize: 14 }}>{t('saveForAFewDays')}</div>
                </MenuItem>

                <MenuItem onClick={() => handleClose('space_type')}>
                    <div style={{ fontSize: 14 }}>{t('saveForAllSimilarObjects')}</div>
                </MenuItem>
                {items?.map((el) => {
                    return (
                        <MenuItem onClick={() => handleClose(el?.hash)} key={el.hash}>
                            <div style={{ fontSize: 14 }}>
                                {t('saveForGroup')}

                                <b style={{ marginLeft: 5 }}>{el.name}</b>
                            </div>
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    )
}

import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import SiteHvacDashboard from './SiteHVACDashboard'
import HvacDashboardLayout from './layout'
import ObjectHVACDashboard from './ObjectHvacDashboard'

const HvacDashboardRoutes: FC = () => {
    return (
        <Routes>
            <Route element={<HvacDashboardLayout />} path="/">
                <Route path="/" element={<SiteHvacDashboard />} />
                <Route path=":objectId" element={<ObjectHVACDashboard />} />
            </Route>
        </Routes>
    )
}
export default HvacDashboardRoutes

import { ExtendedSeriesWithMeasurements } from '@/types/meters'
import HighCharts from 'highcharts'

export interface CommonChartOptions {
    labelGetter?: (series: ExtendedSeriesWithMeasurements) => string
    disableLegend?: boolean
}

export const commonHighchartFormatOptions: HighCharts.Options = {
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            day: '%e %b',
            week: '%e %b %Y',
            month: '%b %Y',
            year: '%Y',
        },
    },
}

import React from 'react'
import { Tooltip, TooltipProps } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

interface InfoTooltipProps {
    title: React.ReactNode
    tooltipProps?: Omit<TooltipProps, 'children' | 'title'>
    iconProps?: React.ComponentProps<typeof InfoOutlined>
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ title, tooltipProps, iconProps }) => (
    <Tooltip title={title} {...tooltipProps} arrow>
        <InfoOutlined {...iconProps} />
    </Tooltip>
)

export default InfoTooltip

import {
    Alert,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { help } from '../../services/user.service'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Support from '../../assets/img/help/support.png'
import { useTranslation } from 'react-i18next'

export const ContactForm = () => {
    const me = useSelector((state) => state.meStore?.me)
    const { t } = useTranslation('contact')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [selectedSite, setSelectedSite] = useState(null)
    const minLength = 3
    const handleSubmit = async () => {
        setError('')
        setLoading(true)
        if (!title || !message) {
            setError(t('errors.missingRequiredFields'))
        } else if (title?.length < 3 || message?.length < 3) {
            setError(t('errors.minLength', { minLength }))
        } else {
            await help({
                title: `[${selectedSite?.Name}] ${title}`,
                message,
                email: me?.Email,
                hash: selectedSite?.Hash,
            })
            setSuccess(true)
            setTitle('')
            setMessage('')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (me?.sites?.length > 0) {
            setSelectedSite(me?.sites[0])
        }
    }, [me])
    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Typography component="h2" variant="h4" gutterBottom>
                    {t('pageTitle')}
                </Typography>
            </Grid>

            <Grid item md={7}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <TextField
                            label={t('fields.object')}
                            placeholder={t('fields.objectPlaceholder')}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            style={{ background: 'white' }}
                            required
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                id="input-with-icon-adornment"
                                value={me?.Email}
                                fullWidth
                                disabled={true}
                                style={{ background: '#eeeeee' }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                id="selected-site"
                                value={selectedSite?.Hash || ''}
                                onChange={(e) => {
                                    let find = me?.sites?.find((el) => el?.Hash === e.target.value)
                                    setSelectedSite(find)
                                }}
                                fullWidth
                                style={{ background: 'white' }}
                            >
                                {me?.sites?.map((el) => {
                                    return (
                                        <MenuItem key={el?.Hash} value={el?.Hash}>
                                            {el?.Name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        {success && <Alert severity="success">{t('feedback.success')}</Alert>}
                        {error && <Alert severity="error">{error}</Alert>}
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            label={t('fields.message')}
                            placeholder={t('fields.messagePlaceholder')}
                            multiline
                            rows={6}
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            style={{ background: 'white' }}
                            required
                        />
                    </Grid>
                    <Grid item md={0} xs={12} />
                    <Grid item md={3} xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button variant="contained" fullWidth onClick={handleSubmit}>
                                {t('actions.send')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={5}>
                <img src={Support} alt="support" style={{ width: '100%', height: 'auto' }} />
            </Grid>
        </Grid>
    )
}

import { Grid } from '@mui/material'
import { MeterType } from '@/types/meters'
import TimeSeriesChart from '../components/timeSeries/TimeSeriesChart'
import { useTranslation } from 'react-i18next'
import { useTimeSeries } from '../timeSeries/useTimeSeries'

const MeteoDashboard = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.meteo' })
    const { period } = useTimeSeries()
    const setMonthly = period?.durationDays > 200
    return (
        <Grid container spacing={2}>
            <TimeSeriesChart
                filters={{
                    type: MeterType.METEO,
                    series: ['temperature_2m'],
                }}
                title={t('temperature')}
                helper={{ description: t('temperatureHelper') }}
                unit="auto"
                type="line"
                options={{ disableLegend: true }}
                aggregateOptions={{ groupBy: setMonthly ? 'day' : 'hour', aggBy: ['avg'] }}
                size="1"
            />
            <TimeSeriesChart
                filters={{
                    type: MeterType.METEO,
                    series: ['sunshine_duration'],
                }}
                title={t('sunshineDuration')}
                unit="hours"
                type="bar"
                options={{ disableLegend: true }}
                aggregateOptions={{ groupBy: setMonthly ? 'month' : 'day', aggBy: ['sum'], unit: 'hours' }}
                size="1"
            />
            <TimeSeriesChart
                filters={{
                    type: MeterType.METEO,
                    series: ['precipitation'],
                }}
                title={t('precipitation')}
                unit="auto"
                type="bar"
                options={{ disableLegend: true }}
                aggregateOptions={{ groupBy: setMonthly ? 'month' : 'day', aggBy: ['sum'] }}
                size="1"
            />
        </Grid>
    )
}

export default MeteoDashboard

import { FC, useState, ReactNode } from 'react'
import {
    Popper,
    IconButton,
    IconButtonProps,
    Link,
    Paper,
    Typography,
    ClickAwayListener,
    PopperProps,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export interface HelpPopperProps {
    helpText: ReactNode
    documentationUrl?: string
    documentationLabel?: ReactNode
    icon?: ReactNode
    iconButtonProps?: IconButtonProps
    popperProps?: Omit<PopperProps, 'open' | 'anchorEl'>
}

const HelpPopper: FC<HelpPopperProps> = ({
    helpText,
    documentationUrl,
    documentationLabel,
    icon,
    iconButtonProps,
    popperProps,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popper' : undefined

    return (
        <>
            <IconButton
                {...iconButtonProps}
                aria-describedby={id}
                onClick={handleClick}
                color={anchorEl ? 'primary' : 'default'}
            >
                {icon ? icon : <InfoOutlinedIcon />}
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl} sx={{ maxWidth: '300px' }} {...popperProps}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper sx={{ p: 2 }}>
                        <Typography>
                            {helpText}
                            {documentationUrl && documentationLabel && (
                                <Link href={documentationUrl} target="_blank" rel="noopener noreferrer">
                                    {' '}
                                    {documentationLabel}
                                </Link>
                            )}
                        </Typography>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default HelpPopper

import { FC } from 'react'
import { useSite } from '../useSite'
import { Paper } from '@mui/material'
import SiteHeader from './SiteHeader'
import EnergySummary from './EnergySummary'

const SiteHome: FC = () => {
    const { site } = useSite()
    return (
        <Paper>
            <SiteHeader site={site} />
            <EnergySummary siteHash={site.hash} />
        </Paper>
    )
}

export default SiteHome

import { VFC } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import SiteYearlyConsumptionsBarChart from '../dashboard/components/energyConsumptions/YearlyConsumptionsBarChart'
import DpeLabels from './components/Dpe'
import { useTranslation } from 'react-i18next'

const EnergySummary: VFC<{ siteHash: string }> = ({ siteHash }) => {
    const { t } = useTranslation('site')
    return (
        <Box p={2}>
            <Typography variant="h3" gutterBottom>
                {t('home.energySummary')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={4}>
                    <DpeLabels siteHash={siteHash} />
                </Grid>
                <Grid item xs={12} sm={9} md={8}>
                    <SiteYearlyConsumptionsBarChart siteHash={siteHash} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default EnergySummary

import { ParsedObject, SpaceType } from '../../types'
import { Card, Typography, Grid, useTheme, Chip, Tooltip } from '@mui/material'
import { SPACE_TYPE_IMG_SRC, MODE_ICONS } from '../../constants'
import PilotChip from './PilotChip'
import OccupancyIndicator from './OccupancyIndicator'
import { Link } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import { useTranslation } from 'react-i18next'
import { errors_codes } from '@/utils/errors'

const getErrorDescription = (pluginId: number | undefined, error: string | undefined) => {
    if (!pluginId || !error) {
        return null
    }
    // Recursive function to search for the pluginId and error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findError = (obj: any, pluginId: number, error: string) => {
        // Check if pluginId exists as a key in the current level
        if (obj?.[pluginId] && obj?.[pluginId]?.[error]) {
            return obj[pluginId][error]
        }

        // Recursively search in all subkeys
        for (const key of Object.keys(obj)) {
            const _obj = obj[key]
            if (_obj && _obj?.[error]) {
                return _obj?.[error]
            }
        }
        return null // Return null if no match found
    }

    return findError(errors_codes, pluginId, error)
}

export interface ObjectCardProps {
    object: ParsedObject
}

const ObjectCard = ({ object }: ObjectCardProps) => {
    const { objectId, spaceType, pilot, state, mode, setpointTemperature, constraints, occupied, isError, error } =
        object
    const { spacing } = useTheme()
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })

    return (
        <Link to={isError ? '' : `${objectId}`} onClick={isError ? () => alert(t('objectError')) : undefined}>
            <Card
                sx={{
                    height: spacing(13),
                    backgroundImage: spaceType ? `url(${SPACE_TYPE_IMG_SRC[spaceType]})` : undefined,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    boxShadow: state ? '0px 0px 4px 4px rgba(118,255,3,0.44)' : 'none',
                    backgroundBlur: state ? 4 : 0,
                }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    height="100%"
                    p={1}
                    sx={{
                        backgroundColor: state ? 'none' : 'rgba(0,0,0,0.6)',
                    }}
                >
                    <Grid item container justifyContent="space-between">
                        {constraints && (
                            <Grid item>
                                <Typography variant="caption" color="common.white" fontWeight="medium">
                                    {constraints?.min?.toFixed(0)}°C - {constraints?.max?.toFixed(0)}°C
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            {setpointTemperature && (
                                <Chip
                                    icon={<img src={MODE_ICONS[mode]} width={16} />}
                                    label={`${setpointTemperature.toFixed(0)}°C`}
                                    sx={{ backgroundColor: 'grey.200', mr: 1, fontSize: 10, fontWeight: 'bold' }}
                                    size="small"
                                />
                            )}
                            <PilotChip pilot={pilot} label={t(`objectCard.pilot.${pilot ? 'ON' : 'OFF'}`)} />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" alignItems="baseline">
                        <Typography
                            variant="subtitle1"
                            color="common.white"
                            fontWeight="bold"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                fontSize: object?.name?.length > 20 ? 12 : 15,
                                lineHeight: 1.2,
                            }}
                        >
                            {object?.name?.length > 40
                                ? object?.name?.substring(0, 40)?.toUpperCase()
                                : object?.name?.toUpperCase()}
                            {isError && (
                                <Tooltip
                                    title={`Erreur: ${error} ${getErrorDescription(object.pluginId, error) || ''}`}
                                    placement="top"
                                >
                                    <WarningIcon color="warning" />
                                </Tooltip>
                            )}
                        </Typography>
                        {spaceType === SpaceType.HOTEL_ROOM && <OccupancyIndicator occupied={occupied} />}
                    </Grid>
                </Grid>
            </Card>
        </Link>
    )
}

export default ObjectCard

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useState } from 'react'
import { Box, CssBaseline, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Header from './header.component'
import NavDrawer, { useEnableNavDrawer } from './navDrawer.component'
import Footer from './footer.component'
import { PERMANENT_DRAWER_BREAKPOINT, DRAWER_WIDTH } from './constants'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../fallbacks/error.component'
import { RoutePaths } from '@/navigation/route'
import { useTranslation } from 'react-i18next'

// Inspired from https://mui.com/material-ui/react-drawer/#responsive-drawer

const GENERAL_LINKS = [RoutePaths.Help, RoutePaths.Contact]

const MainLayout: FC = () => {
    const [mobileOpen, setMobileOpen] = useState(false)
    const theme = useTheme()
    const isPermanent = useMediaQuery(theme.breakpoints.up(PERMANENT_DRAWER_BREAKPOINT))
    const enableNavDrawer = useEnableNavDrawer()
    const { t } = useTranslation()
    const links = GENERAL_LINKS.map((path) => ({
        title: t(`links.${path}`, { defaultValue: path }),
        path: path?.includes('help')
            ? 'https://iodized-fisherman-e9c.notion.site/b926da99ecb34a9f8d116ef3ac0e1a97?v=d351f507b4384357b3b8104e0a99d587&pvs=4'
            : path,
        target: path?.includes('help') ? '_blank' : undefined,
    }))

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Header handleDrawerToggle={enableNavDrawer ? () => setMobileOpen(true) : undefined} links={links} />
                <NavDrawer
                    variant={isPermanent ? 'permanent' : 'temporary'}
                    onClose={() => setMobileOpen(false)}
                    open={isPermanent || mobileOpen}
                />
                <Box
                    component={'main'}
                    sx={{
                        flexGrow: 1,
                        py: { xs: 5, md: 3 },
                        px: { xs: 0, sm: 1, md: 2, lg: 3 },
                        width: { [PERMANENT_DRAWER_BREAKPOINT]: `calc(100% - ${DRAWER_WIDTH}px)` },
                        minHeight: '100vh',
                    }}
                >
                    <Toolbar />
                    <ErrorBoundary fallback={<ErrorFallback />}>
                        <Outlet />
                    </ErrorBoundary>
                </Box>
            </Box>
            <Footer links={links} />
        </>
    )
}

export default MainLayout

import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import moment from 'moment'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import 'moment/dist/locale/fr' // https://stackoverflow.com/questions/49788259/moment-js-change-locale-not-working
import 'moment/dist/locale/en-gb'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import './App.css'
import { store } from './store/configure.store'
import AuthSlice from './store/slices/auth.slice'
import GlobalSlice from './store/slices/global.slice'
import { initialize } from './utils/ga.utils'
import { setLocale } from './utils/locale/locale.utils'
import { removeLocalStorage } from './utils/global.utils'
import { UserProvider } from './context/useAuth'
import Routes from './navigation/routesWrapper'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import './i18n/config'
import { useTranslation } from 'react-i18next'
import { changePage } from './services/user.service'
import ScrollToTop from './navigation/ScrollToTop'

initialize()

Sentry.init({
    dsn: 'https://8d01ccea883a455abbdd9e4a8c6b73a1@o880323.ingest.sentry.io/6214873',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: import.meta.env.PROD,
    environment: import.meta.env.MODE,
})

const queryClient = new QueryClient()

function App() {
    const { i18n } = useTranslation()
    const getCookie = (name) => {
        var nameEQ = name + '='
        var ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) === ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
        }
        return null
    }

    const init = () => {
        let cookieToken = getCookie('token')
        let token = localStorage.getItem('token') || cookieToken
        if (token) {
            document.cookie = `token=${token}; Domain=a-grid.com`
            localStorage.setItem('token', token)
            store.dispatch(AuthSlice.actions.setToken(token))

            // getRefreshToken()
            if (window?.ReactNativeWebView !== undefined)
                window.ReactNativeWebView.postMessage(JSON.stringify({ token }))
        } else {
            localStorage.removeItem('token')
            // check if we are already on the login page
            if (window.location.pathname !== '/login') {
                window.location.href = '/login'
            }
        }
        initLocale()
    }

    const initLocale = async () => {
        let language = localStorage.getItem('language')
        if (!language) {
            language = 'fr'
            localStorage.setItem('language', language)
        }
        if (!localStorage.getItem('units')) {
            if (language === 'en') {
                localStorage.setItem('units', 'imperial')
            } else {
                localStorage.setItem('units', 'metric')
            }
        }
        await setLocale(language)
        moment.locale(i18n.language)
    }

    useEffect(() => {
        init()
        removeLocalStorage()
    }, [])

    useEffect(() => {
        let path = window.location.pathname
        store.dispatch(GlobalSlice.actions.setPath(path))
        store.dispatch(GlobalSlice.actions.setWidth(window.screen.width))
    })
    const LocationChangeHandler = () => {
        const location = useLocation()

        useEffect(() => {
            let path = location.pathname

            let searchParams = new URLSearchParams(location.search)
            let hash = searchParams.get('site') || ''
            changePage({ path, hash })
            store.dispatch(GlobalSlice.actions.setPath(path))
            store.dispatch(GlobalSlice.actions.setWidth(window.screen.width))
        }, [location])

        return null
    }

    return (
        <React.Fragment>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            <UserProvider>
                                <Routes />
                                <ScrollToTop />
                                <LocationChangeHandler />
                            </UserProvider>
                        </Router>
                    </QueryClientProvider>
                </ThemeProvider>
            </Provider>
        </React.Fragment>
    )
}

export default App

import { FC } from 'react'
import { Outlet, useParams, Link } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Divider, Box, Typography, IconButton } from '@mui/material'
import { useSite } from '../../../useSite'
import CloseIcon from '@mui/icons-material/Close'
import InternalErrorFallback from '@/components/fallbacks/error.component'

const HvacDashboardLayout: FC = () => {
    const { objectsQuery } = useSite()
    const { objectId } = useParams()
    const object = objectId ? objectsQuery.data?.find((o) => o.objectId === Number(objectId)) : null
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.hvac.objectHistory' })

    return (
        <ErrorBoundary fallback={<InternalErrorFallback />}>
            {object && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="h5" color="primary.dark" component={Link} to="..">
                            {t('title')}
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{ my: 0.5 }} />
                        <Typography variant="h5" color="primary.main">
                            {object.name}
                        </Typography>
                    </Box>
                    <IconButton component={Link} to=".." size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}
            <Outlet />
        </ErrorBoundary>
    )
}

export default HvacDashboardLayout

import Highcharts from 'highcharts'
import 'highcharts/highcharts-more'
import 'highcharts/modules/no-data-to-display' // Import the no-data-to-display module
import { useTranslation } from 'react-i18next'
type HighchartsInstance = typeof Highcharts
import { useTheme } from '@mui/material'

interface UseHighchartsReturn {
    highchartsInstance: HighchartsInstance
    getColor: (index: number) => string | Highcharts.GradientColorObject | Highcharts.PatternObject | undefined
}

const useHighcharts = (): UseHighchartsReturn => {
    const highchartsInstance = Highcharts
    const { i18n, t } = useTranslation('common')
    const { palette, typography } = useTheme()

    // Set the translations
    highchartsInstance.setOptions({
        lang: {
            locale: i18n.language,
            noData: t('charts.noData'),
        },
        noData: {
            style: {
                fontSize: `${typography.subtitle2.fontSize}px`,
                color: palette.text.secondary,
            },
        },
    })

    const getColor = (index: number) =>
        Highcharts.getOptions().colors?.[index % (Highcharts.getOptions().colors?.length || 1)]

    return { highchartsInstance, getColor }
}

export default useHighcharts

import React from 'react'
import { FC } from 'react'
import { Paper, Grid, GridProps, Typography, Alert, IconButton, Collapse, Link } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// Flex Sizing containers
const SIZES = ['1/4', '1/3', '2/3', '1/2', '1'] as const

export type ChartSize = (typeof SIZES)[number]

const BREAKPOINTS: Record<ChartSize, Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>> = {
    '1/4': { xs: 12, md: 3 },
    '1': { xs: 12 },
    '1/2': { xs: 12, md: 6 },
    '1/3': { xs: 12, md: 4 },
    '2/3': { xs: 12, md: 8 },
}

interface ChartHelperContent {
    description: React.ReactNode
    documentationUrl?: string
    documentationLabel?: string
}

interface ChartHelperProps extends ChartHelperContent {
    open: boolean
    onClose: () => void
}

const ChartHelper: FC<ChartHelperProps> = ({ description, documentationUrl, documentationLabel, open, onClose }) => {
    return (
        <Collapse in={open} sx={{ mb: open ? 2 : 0 }}>
            <Alert severity="info" variant="outlined" onClose={onClose} sx={{ mb: 2 }}>
                {description}
                {documentationUrl && documentationLabel && (
                    <Link href={documentationUrl} target="_blank" rel="noopener noreferrer" sx={{ ml: 1 }}>
                        {documentationLabel}
                    </Link>
                )}
            </Alert>
        </Collapse>
    )
}

export interface ChartContainerProps {
    title: React.ReactNode
    unit?: string | null
    size?: ChartSize
    helper?: ChartHelperContent
}

const ChartContainer: FC<ChartContainerProps & { children: React.ReactNode }> = ({
    title,
    unit,
    helper,
    size = '1',
    children,
}) => {
    const [helperOpen, setHelperOpen] = React.useState(false)
    return (
        <Grid item {...BREAKPOINTS[size]}>
            <Paper sx={(theme) => ({ p: 4, borderRadius: theme.shape.borderRadius / 2, height: '100%' })}>
                <Grid container justifyContent="flex-start" spacing={1} alignItems="center" mb={1}>
                    <Typography variant="h6" component={Grid} item>
                        {title}
                        {unit && (
                            <Typography fontWeight="inherit" component="span" color="text.secondary">
                                &nbsp;&#91;{unit}&#93;
                            </Typography>
                        )}
                    </Typography>
                    {helper && (
                        <Grid item>
                            <IconButton
                                onClick={() => setHelperOpen(!helperOpen)}
                                sx={{ mt: 0.25 }}
                                color={helperOpen ? 'primary' : 'default'}
                            >
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
                {helper && <ChartHelper {...helper} open={helperOpen} onClose={() => setHelperOpen(false)} />}
                <ErrorBoundary fallback={<Typography>Error rendering component</Typography>}>{children}</ErrorBoundary>
            </Paper>
        </Grid>
    )
}

export default ChartContainer

import React, { FC } from 'react'
import { Typography, Grid, Box, IconButton, Tooltip, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSite } from './useSite'
import HelpPopper, { HelpPopperProps } from '@/components/Global/HelpPopper'

interface SiteHeaderProps {
    title: React.ReactNode
    onBack?: () => void
    helper?: HelpPopperProps
}

const SiteHeader: FC<SiteHeaderProps> = ({ title, onBack, helper }) => {
    const params = useParams<{ siteHash: string; objectId?: string }>()
    const { objectsQuery } = useSite()
    const objects = objectsQuery.data || []
    const navigate = useNavigate()
    const { objectId } = params
    const object = objects.find((obj) => String(obj.objectId) === objectId)
    const { t } = useTranslation('common')

    const back = () => {
        navigate(-1)
        onBack && onBack()
    }
    return (
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography variant="h4" component="h2">
                    {title}
                </Typography>
                {object && <Divider orientation="vertical" flexItem sx={{ my: 0.5 }} />}
                {object && (
                    <Typography variant="h4" color="primary">
                        {object.name}
                    </Typography>
                )}
                {helper && <HelpPopper {...helper} />}
            </Box>
            {objectId && (
                <Grid item>
                    <Tooltip title={t('back')}>
                        <IconButton onClick={back}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    )
}

export default SiteHeader

import DeleteIcon from '@mui/icons-material/Delete'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Grid, Tooltip } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { lightGrey, linkBlue } from '@/components/Basic/colors'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const format = (dtime) => {
    let _arr = dtime?.split(':')
    return `${_arr[0]}:${_arr[1]}`
}

const styles = {
    slot: {
        height: '100%',
        background: '#3174ad',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: 4,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        fontSize: 14,
        cursor: 'pointer',
        zIndex: 100,
        padding: 2,
    },
}
export const CalendarLinearComponent = ({ events, setEvents, color }) => {
    const onClick = (id) => {
        setEvents((events) => {
            events = events.filter((event) => {
                return event.id !== id
            })
            return events
        })
    }

    const createEvent = ({ date, startTime, endTime }) => {
        let _start = moment(date).set('hours', startTime?.split(':')[0]).set('minutes', startTime?.split(':')[1])
        let _end = moment(date).set('hours', endTime?.split(':')[0]).set('minutes', endTime?.split(':')[1])
        const newEvent = {
            id: Math.random() * (100000 - 1) + 1,
            startDate: _start.format('YYYY-MM-DDTHH:mm:ss'),
            endDate: _end.format('YYYY-MM-DDTHH:mm:ss'),
            startTime,
            endTime,
        }
        let valid = isValid({ ...newEvent, date })
        if (valid) setEvents([...events, newEvent])
    }

    const expand = ({ id, date }) => {
        // conditions
        let _events = events.filter((event) => {
            return event.id !== id
        })
        let _start = moment(date).set('hours', 0).set('minutes', 0)
        let _end = moment(date).set('hours', 23).set('minutes', 59)

        const newEvent = {
            id,
            startDate: _start.format('YYYY-MM-DDTHH:mm:ss'),
            endDate: _end.format('YYYY-MM-DDTHH:mm:ss'),
            startTime: '00:00:00',
            endTime: '23:59:00',
        }
        let valid = isValid({ ...newEvent, date })

        if (valid) setEvents([..._events, newEvent])
    }

    const isValid = ({ startTime, endTime, date, id }) => {
        let valid = true
        let _events = events?.filter(
            (el) => moment(el?.startDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
        )

        for (let i = 0; i < _events.length; i++) {
            let start = _events[i]?.startTime
            let end = _events[i]?.endTime
            if (
                (startTime > start && startTime < end && id !== events[i].id) ||
                (endTime > start && endTime < end && id !== _events[i].id) ||
                (endTime > end && startTime < start && id !== _events[i].id)
            )
                valid = false
        }
        return valid
    }

    const applyToAll = ({ date }) => {
        let _events = events?.filter(
            (el) => moment(el?.startDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
        )
        let _newEvents = []
        for (let i = 0; i < _events?.length; i++) {
            let startTime = _events[i].startTime
            let endTime = _events[i].endTime
            for (let k = 0; k < 7; k++) {
                let _date = moment(date).startOf('isoWeek').add(k, 'day').format('YYYY-MM-DDTHH:mm:ss')
                const newEvent = {
                    id: Math.random() * (100000 - 1) + 1,
                    startDate: _date,
                    endDate: _date,
                    startTime,
                    endTime,
                }
                _newEvents.push(newEvent)
            }
        }
        setEvents([..._newEvents])
    }

    const weekdays = moment.weekdays().map((wd) => wd.charAt(0).toUpperCase() + wd.slice(1))

    const days = [
        { date: '2001-01-01T00:00:00.000Z', index: 1 },
        { date: '2001-01-02T00:00:00.000Z', index: 2 },
        { date: '2001-01-03T00:00:00.000Z', index: 3 },
        { date: '2001-01-04T00:00:00.000Z', index: 4 },
        { date: '2001-01-05T00:00:00.000Z', index: 5 },
        { date: '2001-01-06T00:00:00.000Z', index: 6 },
        { date: '2001-01-07T00:00:00.000Z', index: 0 },
    ]

    return (
        <Grid container rowSpacing={1} style={{ marginTop: 20 }}>
            {days
                ?.map((el) => {
                    return {
                        ...el,
                        events: events?.filter((event) => moment(event?.startDate).day() === el.index),
                    }
                })
                ?.map((el) => {
                    return (
                        <React.Fragment key={el.id}>
                            <Grid item md={1.5}>
                                <ContentCopyIcon
                                    style={{
                                        color: linkBlue,
                                        fontSize: 14,
                                        cursor: 'pointer',
                                        marginRight: 5,
                                    }}
                                    onClick={() => applyToAll({ date: el.date })}
                                />
                                {weekdays[el.index]}
                            </Grid>
                            <Grid item md={10.5} style={{ position: 'relative' }}>
                                <Background
                                    create={(startTime, endTime) => {
                                        createEvent({ date: el.date, startTime, endTime })
                                    }}
                                    events={el?.events}
                                    onClick={(id) => onClick(id)}
                                    expand={(id) => expand({ id, date: el.date })}
                                    backgroundColor={color}
                                ></Background>
                            </Grid>
                        </React.Fragment>
                    )
                })}
        </Grid>
    )
}

const Background = ({ expand, create, events, onClick, backgroundColor }) => {
    const [dragging, setDragging] = useState(false)
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [type, setType] = useState('right')
    const ref = useRef()

    const initialize = ({ left, right, _type }) => {
        let current = ref.current
        setType(_type)
        setDragging(true)
        setStart(left - current.getBoundingClientRect().left)
        setEnd(right - current.getBoundingClientRect().left)
        let startime = computeTime({
            offsetX: left - current.getBoundingClientRect().left,
            width: current.getBoundingClientRect().width,
        })
        setStartTime(startime)
        let endtime = computeTime({
            offsetX: right - current.getBoundingClientRect().left,
            width: current.getBoundingClientRect().width,
        })
        setEndTime(endtime)
    }

    const onMouseDown = (e) => {
        let current = ref.current
        let offset = e.nativeEvent.clientX
        if (e?.target?.classList?.contains('agrid-slot')) return
        // if(e.target.className)
        if (!dragging) {
            setDragging(true)
            setStart(offset - current.getBoundingClientRect().left)
            let startime = computeTime({
                offsetX: offset - current.getBoundingClientRect().left,
                width: current.getBoundingClientRect().width,
            })
            setStartTime(startime)
            setEnd(null)
        } else {
            onMouseUp()
        }
    }
    const onMouseMove = (e) => {
        if (dragging) {
            let offset = e.nativeEvent.clientX
            let _offset = offset - ref.current.getBoundingClientRect().left

            if (type === 'right') {
                if (_offset <= start) _offset = start
                setEnd(_offset)
                let endTime = computeTime({
                    offsetX: _offset,
                    width: ref.current.getBoundingClientRect().width,
                })
                setEndTime(endTime)
            } else {
                if (_offset >= end) _offset = end
                setStart(_offset)
                let startTime = computeTime({
                    offsetX: _offset,
                    width: ref.current.getBoundingClientRect().width,
                })
                setStartTime(startTime)
            }
        }
    }
    const onMouseUp = (e) => {
        let endTime = computeTime({
            offsetX: end,
            width: ref.current.getBoundingClientRect().width,
        })
        let startTime = computeTime({
            offsetX: start,
            width: ref.current.getBoundingClientRect().width,
        })
        if (startTime !== endTime && end > start) create(startTime, endTime)

        setDragging(false)
        setStart(null)
        setEnd(null)
        setStartTime(null)
        setEndTime(null)
        setType('right')

        // voir offsetx de native event
    }
    const computeTime = ({ width, offsetX }) => {
        let full_width = 24 * 60
        let computed = (offsetX / width) * full_width
        let hours = parseInt(computed / 60)
        let minutes = parseInt(computed % 60)
        minutes = Math.round(minutes / 5) * 5
        if (minutes === 60) {
            hours += 1
            minutes = 0
        }
        if (hours == 24) {
            hours = 23
            minutes = 59
        }
        let out = `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:00`
        return out
    }

    return (
        <div
            style={{
                width: '100%',
                left: 0,
                top: 0,
                height: 50,
                position: 'relative',
                border: `1px solid ${lightGrey}`,
                borderRadius: 4,
                cursor: 'pointer',
            }}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            ref={ref}
        >
            <div
                style={{
                    color: lightGrey,
                    position: 'absolute',
                    left: 2,
                    fontSize: 12,
                }}
                className="noselect"
            >
                00:00
            </div>
            <div
                style={{
                    color: lightGrey,
                    position: 'absolute',
                    left: 'calc(25% + 3px)',
                    fontSize: 12,
                }}
                className="noselect"
            >
                06:00
            </div>
            <div
                style={{
                    color: lightGrey,
                    position: 'absolute',
                    left: 'calc(50% + 3px)',
                    fontSize: 12,
                }}
                className="noselect"
            >
                12:00
            </div>
            <div
                style={{
                    color: lightGrey,
                    position: 'absolute',
                    left: 'calc(75% + 3px)',
                    fontSize: 12,
                }}
                className="noselect"
            >
                18:00
            </div>
            {[25, 50, 75].map((el) => {
                return (
                    <div
                        key={el}
                        style={{
                            position: 'absolute',
                            width: 1,
                            height: 50,
                            background: lightGrey,
                            left: `${el}%`,
                            top: 0,
                        }}
                    />
                )
            })}

            {events?.map((event) => {
                return (
                    <Slot
                        key={event.id}
                        backgroundColor={backgroundColor}
                        startTime={event.startTime}
                        endTime={event.endTime}
                        id={event.id}
                        onClick={() => onClick(event?.id)}
                        onGrab={({ _type, left, right }) => {
                            onClick(event?.id)
                            initialize({
                                left: left,
                                right,
                                _type: _type,
                            })
                        }}
                        expand={() => {
                            expand(event?.id)
                        }}
                    />
                )
            })}
            {start !== null && end !== null && (
                <div
                    style={{
                        ...styles.slot,
                        width: end - start,
                        marginLeft: start,
                        backgroundColor: backgroundColor ? backgroundColor : '#3174ad',
                    }}
                    className="noselect"
                >
                    {format(startTime)}-{format(endTime)}
                </div>
            )}
        </div>
    )
}

const Slot = ({ startTime, endTime, id, onClick, onGrab, expand, backgroundColor }) => {
    const [left, setLeft] = useState(0)
    const [right, setRight] = useState(0)
    let ref = useRef()
    useEffect(() => {
        init(startTime, endTime)
    }, [startTime, endTime])

    const init = (startTime, endTime) => {
        if (!startTime || startTime?.split(':')?.length !== 3 || !endTime || endTime?.split(':')?.length !== 3) return
        let full_width = 24 * 3600
        let _startTime = startTime?.split(':')
        let start_hours = parseInt(_startTime[0])
        let start_minutes = parseInt(_startTime[1])
        let start_seconds = start_hours + start_minutes > 0 ? 60 : 0

        let _endTime = endTime?.split(':')
        let end_hours = parseInt(_endTime[0])
        let end_minutes = parseInt(_endTime[1])
        let end_seconds = end_hours + end_minutes > 0 ? 60 : 0
        let _start = ((start_hours * 3600 + start_minutes * 60 + start_seconds) / full_width) * 100
        let _end = ((end_hours * 3600 + end_minutes * 60 + end_seconds) / full_width) * 100

        setLeft(_start)
        setRight(_end)
    }

    return (
        <>
            <Tooltip title={`${format(startTime)}-${format(endTime)}`}>
                <div
                    style={{
                        ...styles.slot,
                        width: `${right - left}%`,
                        marginLeft: `${left}%`,
                        fontSize: right - left > 10 ? 14 : 8,
                        cursor: 'auto',
                        backgroundColor: backgroundColor ? backgroundColor : '#3174ad',
                    }}
                    className="noselect agrid-slot"
                    ref={ref}
                >
                    {right - left > 20 ? `${format(startTime)} - ${format(endTime)}` : null}
                    <DeleteIcon
                        className="noselect agrid-slot"
                        style={{ cursor: 'pointer', fontSize: 18, marginLeft: 5 }}
                        onClick={onClick}
                    />
                    <OpenInFullIcon
                        className="noselect agrid-slot"
                        style={{ cursor: 'pointer', fontSize: 16, marginLeft: 5 }}
                        onClick={expand}
                    />
                    <div
                        style={{
                            width: 10,
                            cursor: 'grab',
                            height: '100%',
                            //   background: "red",
                            position: 'absolute',
                            //   marginLeft: `${left}%`,
                            left: 0,
                            zIndex: 1000,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        className="agrid-slot"
                        onMouseDown={(e) => {
                            //   let offset = e.nativeEvent.clientX;
                            let offset = ref.current.getBoundingClientRect().left
                            onGrab({
                                _type: 'left',
                                left: offset,
                                right: offset + ref.current.getBoundingClientRect().width,
                            })
                        }}
                    >
                        <NavigateBeforeIcon className="agrid-slot" style={{ marginLeft: 2, fontSize: 15 }} />
                    </div>
                    <div
                        style={{
                            width: 10,
                            cursor: 'grab',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            //   marginLeft: `calc(${left}% + ${
                            //     ref?.current?.getBoundingClientRect().width
                            //   }px - 10px)`,
                            right: 0,
                            zIndex: 1000,
                        }}
                        className="agrid-slot"
                        onMouseDown={(e) => {
                            //   let offset = e.nativeEvent.clientX;
                            let offset = ref.current.getBoundingClientRect().right
                            onGrab({
                                _type: 'right',
                                right: offset,
                                left: offset - ref.current.getBoundingClientRect().width,
                            })
                        }}
                    >
                        <NavigateNextIcon className="agrid-slot" style={{ marginRight: 2, fontSize: 15 }} />
                    </div>
                </div>
            </Tooltip>
        </>
    )
}

import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import SiteDashboardFallback from '../dashboard//fallback'
import { RealtimeProvider, useRealtime } from './useRealtime'
import SiteHeader from '../siteHeader.component'
import { useTranslation } from 'react-i18next'

const RealtimeComponent: FC = () => {
    const { t } = useTranslation('site')
    const { clearModifications } = useRealtime()
    return (
        <>
            <SiteHeader
                title={t('navLinks.realtime')}
                onBack={clearModifications}
                helper={{
                    helpText: t('realtime.helper.description'),
                    documentationUrl: t('realtime.helper.docLink'),
                    documentationLabel: t('realtime.helper.docLabel'),
                    iconButtonProps: { sx: { mt: 0.25 } },
                }}
            />
            <Outlet />
        </>
    )
}

const RealtimeLayout: FC = () => (
    <ErrorBoundary fallbackRender={SiteDashboardFallback}>
        <RealtimeProvider>
            <RealtimeComponent />
        </RealtimeProvider>
    </ErrorBoundary>
)

export default RealtimeLayout

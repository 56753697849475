import { FC, ReactNode, useEffect, useState } from 'react'
import { Skeleton, Box, IconButton, Typography } from '@mui/material'
import { getRollingYear } from './utils/getRollingYear'
import { Card } from '@mui/material'
import { EnedisDisabled } from '../enedis-disabled.component'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import { useTranslation } from 'react-i18next'
import { getSeriesMeasurements } from '@/services/meters.service'
import { useQueries } from '@tanstack/react-query'
import { TimeSeriesProvider, useTimeSeries } from '../../timeSeries/useTimeSeries'
import { MeterType } from '@/types/meters'

const chartFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
    },
    exporting: {
        enabled: false,
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            month: '%b %y',
        },
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.0f} kWh</b>',
    },
    plotOptions: {
        series: {
            animation: false,
            stacking: 'normal',
        },
    },
}

type FormattedSeries = { name: string; data?: { x: string; y: number }[] }
interface YearlyConsumptionsBarChartProps {
    energySeries: FormattedSeries[]
    actions?: ReactNode
}

const YearlyConsumptionsBarChart: FC<YearlyConsumptionsBarChartProps> = ({ energySeries, actions }) => {
    const { t } = useTranslation('connect')

    return (
        <Card sx={{ p: 2, position: 'relative' }} variant="outlined">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {`${t('energy.rolling_year_consumption')} (kWh)`}{' '}
                </Typography>
                {actions}
            </Box>
            <HighchartsChart
                containerProps={{ style: { height: '320px' } }}
                options={{
                    ...chartFormatOptions,
                    series: energySeries.map((s) => ({
                        ...s,
                        type: 'column',
                    })),
                }}
            />
            {energySeries.length === 0 && <EnedisDisabled loading={false} />}
        </Card>
    )
}

interface SiteYearlyConsumptionsBarChartProps {
    siteHash: string
}

export const SiteYearlyConsumptionsBarChart: FC<SiteYearlyConsumptionsBarChartProps> = ({ siteHash }) => {
    const { series, isLoading: isSeriesLoading } = useTimeSeries()
    const energySeries = series.filter((s) => s.type === MeterType.ENERGY && s.name === 'energy')
    const [monthsOffset, setMonthsOffset] = useState(0)
    const period = getRollingYear({ nMonths: 13, offset: monthsOffset })
    const { data } = useQueries({
        queries: energySeries.map((s) => ({
            queryKey: ['series-monthly-measurements', s.id, period],
            queryFn: () =>
                getSeriesMeasurements(siteHash, s.meterId, s.id, {
                    from: period.startDate,
                    to: period.endDate,
                    groupBy: 'month',
                    aggBy: ['sum'],
                }),
            staleTime: Infinity,
        })),
        combine: (results) => ({
            isLoading: results.some((r) => r.isLoading),
            data: results.map((r, index) => ({ ...energySeries[index], measurements: r.data || [] })),
        }),
    })
    const [formattedSeries, setFormattedSeries] = useState<FormattedSeries[]>([])
    useEffect(() => {
        if (data.every((s) => s.measurements.length > 0)) {
            setFormattedSeries(
                data.map((s) => ({
                    name: s.meterName,
                    data: s.measurements?.map((m) => ({
                        x: m.timestamp,
                        y: m.sum as number,
                    })),
                }))
            )
        }
    }, [data])
    if (isSeriesLoading) {
        return <Skeleton variant="rectangular" width={'100%'} height={300} />
    }
    if (!data) {
        return <EnedisDisabled loading={false} />
    }

    return (
        <YearlyConsumptionsBarChart
            energySeries={formattedSeries}
            actions={
                <Box sx={{ display: 'flex', mt: -0.5 }}>
                    <IconButton onClick={() => setMonthsOffset((prev) => prev - 1)} size="small">
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => setMonthsOffset((prev) => prev + 1)}
                        disabled={monthsOffset >= 0}
                        size="small"
                    >
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
            }
        />
    )
}

const SiteYearlyConsumptionsBarChartWrapper: FC<SiteYearlyConsumptionsBarChartProps> = ({ siteHash }) => {
    return (
        <TimeSeriesProvider>
            <SiteYearlyConsumptionsBarChart siteHash={siteHash} />
        </TimeSeriesProvider>
    )
}

export default SiteYearlyConsumptionsBarChartWrapper

import { FC } from 'react'
import { usePeriodShortcuts } from './usePeriodShortcuts'
import { NavCalendarComponent } from '@/components/Global/nav-calendar.component'
import { Box, Chip, BoxProps } from '@mui/material'

export const PeriodSelector: FC<BoxProps> = (boxProps) => {
    const { shortcuts } = usePeriodShortcuts()

    return (
        <Box {...boxProps} sx={{ ...boxProps.sx, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box mr={2}>
                <NavCalendarComponent />
            </Box>

            {shortcuts.map(({ label, active, onSelect }) => (
                <Chip key={label} label={label} color={active ? 'primary' : 'default'} onClick={onSelect} />
            ))}
        </Box>
    )
}

export default PeriodSelector

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, Button, ButtonProps } from '@mui/material'
import { supportedLanguages, SupportedLanguage } from '@/i18n/config'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment'

const LanguageSwitch: FC<ButtonProps> = (buttonProps) => {
    const { t, i18n } = useTranslation()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleLanguageChange = (lang: SupportedLanguage) => {
        i18n.changeLanguage(lang)
        moment.locale(lang)
        handleClose()
    }

    return (
        <>
            <Button onClick={handleClick} {...buttonProps} title={t('language.title')} endIcon={<ExpandMoreIcon />}>
                {t(`language.${i18n.language as SupportedLanguage}`)}
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {supportedLanguages.map((lang) => (
                    <MenuItem key={lang} onClick={() => handleLanguageChange(lang)} selected={i18n.language === lang}>
                        {t(`language.${lang as SupportedLanguage}`)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default LanguageSwitch

export enum MeterType {
    ENERGY = 'ENERGY',
    METEO = 'METEO',
    AGRID_BMS = 'AGRID_BMS',
    OCCUPANCY = 'OCCUPANCY',
}

export enum EnergyMetersSubTypes {
    ELECTRICITY = 'ELECTRICITY',
    GAS = 'GAS',
    URBAN_HEAT = 'URBAN_HEAT',
}

export interface TimeSeries {
    id: string
    meterId: string
    name: string
    unit?: string | null
    createdAt: Date
    updatedAt: Date
}

export type MeterExtraMetadata = Record<string, string | number>

export interface Meter {
    id: string
    siteHash: string
    name: string
    timeZone: string
    type: MeterType
    subType?: EnergyMetersSubTypes | null
    extraMetadata?: MeterExtraMetadata
    series: TimeSeries[]
    createdAt: Date
    updatedAt: Date
}

const measurementGroupByOptions = ['hour', 'day', 'week', 'month', 'time'] as const

export type MeasurementGroupBy = (typeof measurementGroupByOptions)[number]

const measurementAggregationOptions = ['avg', 'sum', 'min', 'max', 'count'] as const

export type MeasurementAggregation = (typeof measurementAggregationOptions)[number]

export type MeasurementValueKey = 'value' | MeasurementAggregation

interface BaseMeasurement {
    timestamp: string
}

export type Measurement = BaseMeasurement & { value?: number } & Partial<Record<MeasurementAggregation, number>>

export enum FeedStatus {
    OK = 'OK',
    PENDING = 'PENDING',
    PROVIDER_ERROR = 'PROVIDER_ERROR',
    INTERNAL_ERROR = 'ERROR',
}

export interface Feed {
    meterId: string
    providerPointId: string
    statusText: string | null
    lastMeasurementTimestamp: string | null
    createdAt: string
    updatedAt: string
    id: number
    provider: string
    type: MeterType
    status: FeedStatus
    providerConfig: Record<string, string | number>
}

export type ExtendedSeries = TimeSeries & Omit<Meter, 'series' | 'id'> & { meterName: string }

export type ExtendedSeriesWithMeasurements = ExtendedSeries & { measurements: Measurement[] } & { label?: string }

import { ExtendedSeriesWithMeasurements } from '@/types/meters'
import sumBy from 'lodash/sumBy'
import meanBy from 'lodash/meanBy'

export type AggregationType = 'sum' | 'average'

const sumSeries = (series: ExtendedSeriesWithMeasurements[]): number =>
    sumBy(series, (s) => sumBy(s.measurements, 'value'))

const averageSeries = (series: ExtendedSeriesWithMeasurements[]): number =>
    meanBy(series, (s) => meanBy(s.measurements, 'value'))

export const aggregateSeries = (series: ExtendedSeriesWithMeasurements[], aggType: AggregationType): number => {
    switch (aggType) {
        case 'sum':
            return sumSeries(series)
        case 'average':
            return averageSeries(series)
        default:
            throw new Error(`Unknown aggregation type: ${aggType}`)
    }
}

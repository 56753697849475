import React, { FC } from 'react'
import { AppBar, Toolbar, IconButton, Box, useMediaQuery, Theme, Link } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import { PERMANENT_DRAWER_BREAKPOINT } from './constants'
import AvatarMenu from './avatarMenu.component'
import HomeIcon from '@mui/icons-material/Home'
import NavLink from './navlink.component'

interface HeaderProps {
    handleDrawerToggle?: () => void
    links?: { title: string; path: string; target?: string }[]
}

const Header: FC<HeaderProps> = ({ handleDrawerToggle, links }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <AppBar position="fixed">
            <Toolbar sx={{ px: 2, display: isMobile ? 'flex' : 'flex', py: isMobile ? 1 : undefined }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        color: 'white',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {!isMobile && (
                            <IconButton sx={{ color: 'inherit' }} LinkComponent={Link} href={'/'}>
                                <HomeIcon />
                            </IconButton>
                        )}
                        {handleDrawerToggle && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ display: { [PERMANENT_DRAWER_BREAKPOINT]: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <div id="header-title-container" />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'white', gap: 1 }}>
                        {!isMobile &&
                            links?.map((link, index) => (
                                <NavLink key={index} title={link.title} path={link.path} target={link?.target} />
                            ))}
                        <AvatarMenu />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header

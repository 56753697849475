import { FC } from 'react'
import { MeasurementsQueryResult } from '../../../timeSeries/types'
import { AggregationType, aggregateSeries } from '../../../timeSeries/aggregation/aggregateSeries'
import { Typography, Box } from '@mui/material'
import { formatNumber } from '@/utils/global.utils'

export interface NumberFormatOptions {
    style?: 'decimal' | 'currency' | 'percent'
    currency?: string
    minimumFractionDigits?: number
    maximumFractionDigits?: number
}

export interface TimeSeriesNumberChartOptions {
    aggType: AggregationType
    numberFormat?: NumberFormatOptions
}
interface TimeSeriesPieTableProps {
    options?: TimeSeriesNumberChartOptions
    series: MeasurementsQueryResult[]
}

const NumberChart: FC<TimeSeriesPieTableProps> = ({ series, options }) => {
    const value = aggregateSeries(
        series.map((s) => s.data),
        options?.aggType || 'sum'
    )
    const unit = series[0]?.data.unit
    // const formattedValue = new Intl.NumberFormat('fr-FR', options?.numberFormat).format(value)
    const formattedValue = formatNumber(value?.toFixed(1))
    return (
        <Box>
            <Typography variant="h2">
                {formattedValue} {unit}
            </Typography>
        </Box>
    )
}

export default NumberChart
